'use client';

import { FC, SVGProps } from 'react';

const IconLogo: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="283" height="65" viewBox="0 0 283 65" {...props}>
      <g className="icon-logo-mark">
        <path d="M0 63.6152H21.3918V21.3008H64.0428V-0.00341797H0V63.6152Z" fill="#A857F1" />
        <path
          d="M45.5945 64.8878C56.2523 64.8878 64.8922 56.2479 64.8922 45.5901C64.8922 34.9323 56.2523 26.2925 45.5945 26.2925C34.9367 26.2925 26.2969 34.9323 26.2969 45.5901C26.2969 56.2479 34.9367 64.8878 45.5945 64.8878Z"
          fill="#A857F1"
        />
      </g>

      <g className="icon-logo-type">
        <path
          d="M259.657 62.7285C255.717 62.7285 252.686 61.6893 250.564 59.6108C248.485 57.5323 247.446 54.5012 247.446 50.5174V31.6812H239.457V20.7041H247.446V6.41455H260.762V20.7041H272.128V31.6812H260.762V47.7245C260.762 49.0668 261.108 50.0844 261.801 50.7773C262.494 51.4701 263.511 51.8165 264.854 51.8165H272.128V62.7285H259.657Z"
          fill="currentColor"
        />
        <path
          d="M195.352 20.7035H207.433V24.211C208.818 22.6954 210.507 21.5263 212.499 20.7035C214.534 19.8375 216.786 19.3828 219.254 19.3395C221.679 19.2962 223.952 19.7076 226.074 20.5736C228.196 21.4397 230.036 22.6521 231.595 24.211C233.154 25.8131 234.345 27.7834 235.168 30.1217C236.034 32.4167 236.467 35.2313 236.467 38.5655V62.7279H223.086V39.9295C223.086 38.1975 222.913 36.8551 222.567 35.9025C222.264 34.9065 221.766 34.0621 221.073 33.3693C219.817 32.1569 218.107 31.5506 215.942 31.5506C213.776 31.5506 212.066 32.1569 210.81 33.3693C210.074 34.0621 209.533 34.9065 209.186 35.9025C208.883 36.8551 208.732 38.1975 208.732 39.9295V62.7279H195.352V20.7035Z"
          fill="currentColor"
        />
        <path
          d="M167.21 63.8325C162.836 63.8325 158.852 62.8582 155.258 60.9096C151.708 59.0043 148.893 56.3412 146.814 52.9204C144.779 49.5429 143.762 45.7972 143.762 41.6836C143.762 37.5699 144.779 33.8026 146.814 30.3818C148.893 26.961 151.708 24.2762 155.258 22.3277C158.852 20.3358 162.836 19.3398 167.21 19.3398C171.583 19.3398 175.545 20.3358 179.096 22.3277C182.69 24.2762 185.505 26.961 187.54 30.3818C189.575 33.8026 190.593 37.5699 190.593 41.6836C190.593 45.7972 189.575 49.5429 187.54 52.9204C185.505 56.3412 182.69 59.0043 179.096 60.9096C175.545 62.8582 171.583 63.8325 167.21 63.8325ZM167.21 52.0111C169.028 52.0111 170.652 51.5564 172.081 50.6471C173.553 49.7377 174.701 48.5036 175.524 46.9447C176.346 45.3859 176.758 43.6322 176.758 41.6836C176.758 39.735 176.346 37.9813 175.524 36.4224C174.701 34.8635 173.553 33.6511 172.081 32.7851C170.652 31.8757 169.028 31.421 167.21 31.421C165.348 31.421 163.681 31.8757 162.208 32.7851C160.779 33.6511 159.653 34.8635 158.831 36.4224C158.008 37.9813 157.597 39.735 157.597 41.6836C157.597 43.6322 158.008 45.3859 158.831 46.9447C159.653 48.5036 160.801 49.7377 162.273 50.6471C163.746 51.5564 165.391 52.0111 167.21 52.0111Z"
          fill="currentColor"
        />
        <path
          d="M114.129 20.7037H126.145V25.7051C127.574 23.9297 129.285 22.5657 131.276 21.613C133.312 20.6604 135.498 20.1841 137.837 20.1841C139.352 20.1841 140.824 20.314 142.253 20.5738V33.1746C141.214 32.7849 139.785 32.59 137.967 32.59C134.762 32.59 132.229 33.5643 130.367 35.5129C129.371 36.5089 128.613 37.7213 128.094 39.1503C127.617 40.5792 127.379 42.4196 127.379 44.6713V62.7281H114.129V20.7037Z"
          fill="currentColor"
        />
        <path
          d="M75.8164 -0.665527H109.852V12.0002H89.5214V24.4062H109.462V36.6173H89.5214V62.7283H75.8164V-0.665527Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default IconLogo;
