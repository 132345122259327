import { create } from 'zustand';

export const getInitialState = (set, get) => ({
  // UI
  isHeaderScrolled: false,
  isMobileMenuOpen: false,
  activeDesktopHeaderMenuIndex: null,
  isDemoModalOpen: false,
  isTrialModalOpen: false,
  isProductTourModalOpen: false,
});

export const setHeaderScrolled = (set, get) => (isHeaderScrolled) => {
  set({ isHeaderScrolled });
};

export const setMobileMenuOpen = (set, get) => (isMobileMenuOpen) => {
  set({ isMobileMenuOpen });
};

export const setDesktopHeaderMenuIndex = (set, get) => (activeDesktopHeaderMenuIndex) => {
  set({ activeDesktopHeaderMenuIndex });
};

export const setDemoModalOpen = (set, get) => (isDemoModalOpen) => {
  set({ isDemoModalOpen });
};

export const setTrialModalOpen = (set, get) => (isTrialModalOpen) => {
  set({ isTrialModalOpen });
};

export const setProductTourModalOpen = (set, get) => (isProductTourModalOpen) => {
  set({ isProductTourModalOpen });
};

export default create((set, get) => ({
  // State
  ...getInitialState(set, get),

  // Actions
  setHeaderScrolled: setHeaderScrolled(set, get),
  setMobileMenuOpen: setMobileMenuOpen(set, get),
  setDesktopHeaderMenuIndex: setDesktopHeaderMenuIndex(set, get),
  setDemoModalOpen: setDemoModalOpen(set, get),
  setTrialModalOpen: setTrialModalOpen(set, get),
  setProductTourModalOpen: setProductTourModalOpen(set, get),
}));
