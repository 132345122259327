'use client';

import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React, { FC, PropsWithChildren } from 'react';

export interface LinkProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof NextLinkProps>,
    NextLinkProps,
    React.RefAttributes<HTMLAnchorElement>,
    PropsWithChildren {}

const Link: FC<LinkProps> = ({ children, ...props }) => {
  return <NextLink {...props}>{children}</NextLink>;
};

export default Link;
