'use client';

import { FC, SVGProps } from 'react';

const IconShadowHeaderTag: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="56" height="38" viewBox="0 0 56 38" {...props}>
      <g filter="url(#filter-header-tag-blur)">
        <rect x="10" y="10" width="36" height="18" fill="url(#header-tag-linear-gradient)" />
      </g>

      <defs>
        <filter
          id="filter-header-tag-blur"
          x="0"
          y="0"
          width="56"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_4470_56086" />
        </filter>
        <linearGradient
          id="header-tag-linear-gradient"
          x1="11.5"
          y1="25.5"
          x2="46"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5FBAFF" />
          <stop offset="0.235" stopColor="#51E9DD" />
          <stop offset="0.49" stopColor="#FEEF4D" />
          <stop offset="0.82" stopColor="#FE796F" />
          <stop offset="1" stopColor="#EA528E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconShadowHeaderTag;
