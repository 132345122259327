'use client';

import { useEffect, useState } from 'react';

const useClientReady = () => {
  const [isReady, setReady] = useState(false);

  // Know if browser is ready
  useEffect(() => {
    setReady(true);
  }, []);

  return isReady;
};

export default useClientReady;
