import ease from '@/constants/ease';
import { motion } from 'framer-motion';
import isBoolean from 'lodash/isBoolean';
import { ComponentProps, FC, useRef, useState } from 'react';

const TRANSITION = {
  ease: ease.appear,
  duration: 600 / 1000,
};

export interface DrawerProps extends ComponentProps<'div'> {
  renderTrigger: ({ onClick, isOpen }) => any;
  openByDefault?: boolean;
  open?: boolean;
  transitionDuration?: number;
}

const Drawer: FC<DrawerProps> = ({
  renderTrigger = ({ onClick }) => <button onClick={onClick} />,
  openByDefault = false,
  open,
  children,
  transitionDuration = 400,
}) => {
  const heightEl = useRef(null);
  const [isOpen, setIsOpen] = useState(openByDefault);
  const statefulOpen = isBoolean(open) ? open : isOpen;

  const onClickTrigger = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {renderTrigger({
        onClick: onClickTrigger,
        isOpen: statefulOpen,
      })}
      <motion.div
        initial={false}
        animate={{ height: statefulOpen ? 'auto' : 0 }}
        transition={{
          ...TRANSITION,
          duration: transitionDuration / 1000,
        }}
        className="overflow-hidden"
      >
        <div ref={heightEl}>{children}</div>
      </motion.div>
    </div>
  );
};

export default Drawer;
