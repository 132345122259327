'use client';

import ease from '@/constants/ease';
import useClientReady from '@/hooks/useClientReady';
import useStore from '@/stores/useStore';
import cx from 'classnames';
import { animate, motion, useMotionValue } from 'framer-motion';
import { isNumber } from 'lodash';
import { FC, useEffect, useRef } from 'react';
import IconArrowFooterLink from './IconArrowFooterLink';
import IconShadowHeaderTag from './IconShadowHeaderTag';
import Link, { LinkProps } from './Link';

export const HEADER_DESKTOP_MENU_IMAGE_WIDTH = 330;
export const HEADER_DESKTOP_MENU_PADDING = 24;

export interface HeaderDesktopMenuSectionProps {
  title?: string;
  image?: React.ReactNode;
  featuredLinks?: LinkProps[];
  linkColumns?: 1 | 2 | 3 | 4;
  links?: {
    icon?: React.ReactNode;
    title: string;
    subtitle?: string;
    tag?: string;
    href: string;
  }[];
}

export interface HeaderDesktopMenuDrawerProps {
  type: 'drawer';
  title: string;
  sections: HeaderDesktopMenuSectionProps[];
  promoBanner?: React.ReactNode;
}

export interface HeaderDesktopMenuLinkProps {
  type: 'link' | 'primary' | 'secondary';
  title: string;
  href: string;
  action?: 'trial' | 'demo';
}

interface HeaderDesktopMenuProps {
  menu: (HeaderDesktopMenuDrawerProps | HeaderDesktopMenuLinkProps)[];
}

export const HeaderDesktopDrawerSection: FC<HeaderDesktopMenuSectionProps> = ({
  title,
  linkColumns = 1,
  links = [],
  featuredLinks = [],
  image,
}) => {
  const { setMobileMenuOpen, setDesktopHeaderMenuIndex } = useStore();

  const onCloseAllMenus = () => {
    setMobileMenuOpen(false);
    setDesktopHeaderMenuIndex(null);
  };

  const hasLinks = Boolean(links && links.length);
  const hasFeaturedLinks = Boolean(featuredLinks && featuredLinks.length);

  const sectionTitle = title && <p className="text-14/20 font-500">{title}</p>;

  const sectionImage = image && (
    <div className="w-full rounded-10 overflow-hidden" style={{ width: HEADER_DESKTOP_MENU_IMAGE_WIDTH }}>
      {image}
    </div>
  );

  const linkGrid = hasLinks && (
    <div
      className={cx('grid gap-y-3 gap-x-gutter-desktop', {
        'grid-cols-1 w-25': linkColumns === 1,
        'grid-cols-2 w-55': linkColumns === 2,
        'grid-cols-3 w-75': linkColumns === 3,
        'grid-cols-4 w-100': linkColumns === 4,
      })}
    >
      {links.map((link, index) => (
        <div key={index} className="flex gap-1 relative items-start link-arrow">
          {link.icon && <div className="relative top-[2px]">{link.icon}</div>}

          <div className="flex flex-col gap-1">
            <div className="flex gap-1">
              <Link href={link.href} onClick={onCloseAllMenus} className="text-15/19 link-cover">
                {link.title}
              </Link>
              {link.tag && (
                <div>
                  <div className="relative top-[1px]">
                    <div className="absolute inset-[-8px]">
                      <IconShadowHeaderTag
                        width="100%"
                        height="100%"
                        preserveAspectRatio="none"
                        className="absolute left-0 top-0"
                      />
                    </div>
                    <div className="relative top-[1px] bg-white text-gray-500 block rounded-4 px-half text-10/15 h-[15px] uppercase font-mono">
                      {link.tag}
                    </div>
                  </div>
                </div>
              )}

              <IconArrowFooterLink className="link-arrow__icon block" />
            </div>

            {link.subtitle && <p className="text-12/14 text-gray-800">{link.subtitle}</p>}
          </div>
        </div>
      ))}
    </div>
  );

  const featuredLinkGrid = hasFeaturedLinks && (
    <div className="flex flex-col gap-2">
      {featuredLinks.map((link, index) => (
        <div key={index} className="relative flex justify-between items-center">
          <Link href={link.href} onClick={onCloseAllMenus} className="text-16/20 font-500 link-cover">
            {link.title}
          </Link>
          <IconArrowFooterLink className="block" />
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex flex-col gap-3">
      {sectionTitle}
      {sectionImage}
      {linkGrid}
      {featuredLinkGrid}
    </div>
  );
};

const HeaderDesktopMenu: FC<HeaderDesktopMenuProps> = ({ menu = [] }) => {
  const isClientReady = useClientReady();
  const menuWidth = useMotionValue(0);
  const menuHeight = useMotionValue(0);

  // eslint-disable-next-line
  const menuRefs = menu.map(() => useRef<HTMLDivElement | null>(null));

  const { activeDesktopHeaderMenuIndex } = useStore();
  const lastMenuIndex = useRef(activeDesktopHeaderMenuIndex);
  const activeDesktopMenu = menu[activeDesktopHeaderMenuIndex];

  // Set current width and height
  useEffect(() => {
    const menuRef = menuRefs[activeDesktopHeaderMenuIndex];

    if (!menuRef || !menuRef.current) return;

    /*
     * 1. If the menu is not yet initialized, set size IMMEDIATELY
     * 2. If we're changing values, animate them
     */

    if (lastMenuIndex.current === null && isNumber(activeDesktopHeaderMenuIndex)) {
      animate(menuWidth, menuRef.current.offsetWidth, { duration: 0 });
      animate(menuHeight, menuRef.current.offsetHeight, { duration: 0 });
    } else if (
      lastMenuIndex.current !== activeDesktopHeaderMenuIndex &&
      isNumber(lastMenuIndex.current) &&
      isNumber(activeDesktopHeaderMenuIndex)
    ) {
      animate(menuWidth, menuRef.current.offsetWidth, {
        duration: 400 / 1000,
        ease: [0.16, 1, 0.3, 1],
      });

      animate(menuHeight, menuRef.current.offsetHeight, {
        duration: 400 / 1000,
        ease: [0.16, 1, 0.3, 1],
      });
    }

    lastMenuIndex.current = activeDesktopHeaderMenuIndex;
  }, [activeDesktopHeaderMenuIndex, menuRefs]);

  const menuContainers = menu.map((menu, index) => {
    if (menu.type !== 'drawer') return null;

    return (
      <motion.div
        key={index}
        ref={menuRefs[index]}
        animate={{
          opacity: activeDesktopHeaderMenuIndex === index ? 1 : 0,
          pointerEvents: activeDesktopHeaderMenuIndex === index ? 'auto' : 'none',
        }}
        transition={{
          duration: 300 / 1000,
          ease: ease.appear,
        }}
        className="absolute top-0 left-0"
      >
        <div className="flex flex-col gap-3">
          <div className="table table-fixed border-collapse border-none">
            {menu.sections.map((section, index) => (
              <div
                key={index}
                className="table-cell align-top border-x border-solid border-gray-200 first:border-l-0 last:border-r-0 px-3"
              >
                <HeaderDesktopDrawerSection {...section} />
              </div>
            ))}
          </div>
          {menu.promoBanner && <div className="px-3">{menu.promoBanner}</div>}
        </div>
      </motion.div>
    );
  });

  return (
    <div
      className={cx('hidden lg:block absolute top-10 pt-2 left-[50%] translate-x-[-50%] z-20', {
        'pointer-events-none': !activeDesktopMenu,
      })}
    >
      <motion.div
        initial={false}
        animate={{
          opacity: activeDesktopMenu ? 1 : 0,
          y: activeDesktopMenu ? 0 : 20,
        }}
        transition={{
          duration: 300 / 1000,
          ease: ease.appear,
        }}
      >
        <div className="rounded-20 bg-white border-[5px] border-solid border-violet-200 shadow-header-scrolled overflow-hidden px-1 py-4">
          <motion.div
            className="relative"
            initial={false}
            style={{
              width: menuWidth,
              height: menuHeight,
            }}
          >
            {menuContainers}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default HeaderDesktopMenu;
