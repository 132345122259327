'use client';

import useWindowScroll from '@/lib/hooks/useWindowScroll';
import useStore from '@/stores/useStore';
import cx from 'classnames';
import Image from 'next/image';
import { ComponentProps, FC, Fragment, useEffect, useRef } from 'react';
import HeaderDesktopMenu, { HeaderDesktopMenuDrawerProps, HeaderDesktopMenuLinkProps } from './HeaderDesktopMenu';
import HeaderMobileTakeover, {
  HeaderMobileLinkProps,
  HeaderMobileMenuDrawerProps,
  MobileMenuLinkProps,
} from './HeaderMobileTakeover';
import IconLogo from './IconLogo';
import Link, { LinkProps } from './Link';

export const HEADER_SCROLL_THRESHOLD = 50;

export interface DesktopMenuLinkProps {
  type: 'link' | 'primary' | 'secondary';
  title: string;
  href: string;
  action?: 'trial' | 'demo';
}

export interface HeaderProps {
  invert?: boolean;
  forceOpaque?: boolean;
  promoBanner?: React.ReactNode;
  desktopMenu: (HeaderDesktopMenuDrawerProps | HeaderDesktopMenuLinkProps)[];
  desktopMenuLinks: DesktopMenuLinkProps[];
  mobileMenu: (HeaderMobileMenuDrawerProps | HeaderMobileLinkProps)[];
  mobileMenuLinks: MobileMenuLinkProps[];
}

export interface HeaderMenuLinkProps extends LinkProps {
  invert?: boolean;
  scrolled?: boolean;
  title: string;
}

export interface HeaderMenuDrawerTriggerProps extends ComponentProps<'button'> {
  invert?: boolean;
  scrolled?: boolean;
  title: string;
}

export const HeaderDesktopMenuLink: FC<HeaderMenuLinkProps> = ({ href, title, invert, scrolled, ...props }) => (
  <Link
    className={cx('block button button-size-medium', {
      'button-primary-link-header': !invert || scrolled,
      'button-primary-link-header-invert': invert && !scrolled,
    })}
    href={href}
    {...props}
  >
    {title}
  </Link>
);

export const HeaderDesktopDrawerTrigger: FC<HeaderMenuDrawerTriggerProps> = ({
  title,
  invert,
  scrolled,
  className,
  ...props
}) => (
  <button
    className={cx('block button button-size-medium', className, {
      'button-primary-link-header': !invert || scrolled,
      'button-primary-link-header-invert': invert && !scrolled,
    })}
    {...props}
  >
    {title}
  </button>
);

const Header: FC<HeaderProps> = ({
  invert,
  promoBanner,
  forceOpaque,
  desktopMenu = [],
  desktopMenuLinks = [],
  mobileMenu = [],
  mobileMenuLinks = [],
}) => {
  const [scrollY] = useWindowScroll();
  const lastScrollY = useRef(0);

  const {
    isHeaderScrolled,
    isMobileMenuOpen,
    activeDesktopHeaderMenuIndex,
    setHeaderScrolled,
    setMobileMenuOpen,
    setDesktopHeaderMenuIndex,
    setDemoModalOpen,
    setTrialModalOpen,
  } = useStore();

  const isDesktopMenuOpen = Boolean(desktopMenu[activeDesktopHeaderMenuIndex]);

  // Change header scrolled in global store
  useEffect(() => {
    if (scrollY >= HEADER_SCROLL_THRESHOLD && !isHeaderScrolled) {
      setHeaderScrolled(true);
    } else if (scrollY < HEADER_SCROLL_THRESHOLD && isHeaderScrolled) {
      setHeaderScrolled(false);
    }
  }, [scrollY, isHeaderScrolled]);

  const onToggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);

  const onCloseAllMenus = () => {
    setMobileMenuOpen(false);
    setDesktopHeaderMenuIndex(null);
  };

  const onMouseLeaveHeader = () => {
    // Don't effect mobile menu
    if (isMobileMenuOpen) return;

    setMobileMenuOpen(false);
    setDesktopHeaderMenuIndex(null);
  };

  const onClickMenuDrawer = (index) => () => {
    if (index === activeDesktopHeaderMenuIndex) {
      setDesktopHeaderMenuIndex(null);
    } else {
      setDesktopHeaderMenuIndex(index);
    }

    setMobileMenuOpen(false);
  };

  const onMouseEnterMenuDrawer = (index) => () => {
    setDesktopHeaderMenuIndex(index);
    setMobileMenuOpen(false);
  };

  const onClickMenuButton = (action) => {
    switch (action) {
      case 'trial':
        setTrialModalOpen(true);
        break;
      case 'demo':
        setDemoModalOpen(true);
        break;

      default:
        break;
    }
  };

  // TODO: update logo
  const logoColumn = (
    <div className="lg:col-span-2 2xl:col-span-3 flex">
      <Link href="/" className="block">
        <IconLogo
          className={cx('w-[112px] h-[26px] transition-colors duration-300', {
            'text-white': invert && !isMobileMenuOpen && !isHeaderScrolled,
            'text-violet-900': !invert || isMobileMenuOpen || isHeaderScrolled,
          })}
        />
      </Link>
    </div>
  );

  const mobileMenuTrigger = (
    <button
      aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
      onClick={onToggleMobileMenu}
      className="button button-size-circle-medium button-mobile-menu-trigger flex items-center justify-center"
    >
      {isMobileMenuOpen ? (
        <Image
          width={18}
          height={18}
          className="block"
          src="/images/icon-header-mobile-menu-close.svg"
          alt="Close mobile menu"
        />
      ) : (
        <Image
          width={19}
          height={14}
          className="block"
          src="/images/icon-header-mobile-menu-trigger.svg"
          alt="Mobile menu"
        />
      )}
    </button>
  );

  const centerMenuColumn = (
    <div className="lg:col-span-4 2xl:col-span-6 hidden lg:flex lg:justify-center">
      <ul className="flex 2xl:gap-1 justify-center items-center transition-opacity duration-300 ease-appear text-blue-900">
        {desktopMenu.map((column, index) => {
          switch (column.type) {
            case 'link':
              return (
                <div key={index}>
                  <HeaderDesktopMenuLink
                    {...column}
                    invert={invert}
                    scrolled={isHeaderScrolled}
                    onMouseEnter={onCloseAllMenus}
                    onClick={onCloseAllMenus}
                  />
                </div>
              );
            case 'drawer':
              return (
                <div key={index}>
                  <HeaderDesktopDrawerTrigger
                    invert={invert}
                    scrolled={isHeaderScrolled}
                    title={column.title}
                    onMouseEnter={onMouseEnterMenuDrawer(index)}
                    onClick={onClickMenuDrawer(index)}
                    className={cx({
                      active: index === activeDesktopHeaderMenuIndex,
                    })}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </ul>
    </div>
  );

  const rightMenuColumn = (
    <ul className="lg:col-span-6 2xl:col-span-3 flex gap-1 justify-end items-center whitespace-nowrap">
      {desktopMenuLinks.map((link, index) => {
        return (
          <li key={index} className="hidden lg:block">
            {link.href.length ? (
              <Link
                key={index}
                className={cx('block button button-size-medium', {
                  'button-primary-link-header': link.type === 'link' && (!invert || isHeaderScrolled),
                  'button-primary-link-header-invert': link.type === 'link' && invert && !isHeaderScrolled,

                  'button-primary-violet': link.type === 'primary' && (!invert || isHeaderScrolled),
                  'button-primary-white': link.type === 'primary' && invert && !isHeaderScrolled,

                  'button-secondary-gray': link.type === 'secondary' && (!invert || isHeaderScrolled),
                  'button-secondary-violet-300': link.type === 'secondary' && invert && !isHeaderScrolled,
                })}
                href={link.href}
                onClick={onCloseAllMenus}
              >
                {link.title}
              </Link>
            ) : (
              <button
                key={index}
                className={cx('block', {
                  'text-16/16 px-2 font-500': link.type === 'link',
                  'text-white': link.type === 'link' && invert && !isHeaderScrolled,
                  'button button-size-medium': link.type === 'primary' || link.type === 'secondary',
                  'button-primary-violet': link.type === 'primary' && (!invert || isHeaderScrolled),
                  'button-primary-white': link.type === 'primary' && invert && !isHeaderScrolled,
                  'button-secondary-gray': link.type === 'secondary' && (!invert || isHeaderScrolled),
                  'button-secondary-violet-300': link.type === 'secondary' && invert && !isHeaderScrolled,
                })}
                onClick={() => {
                  onClickMenuButton(link.action);
                }}
              >
                {link.title}
              </button>
            )}
          </li>
        );
      })}
      <li className="lg:hidden">{mobileMenuTrigger}</li>
    </ul>
  );

  const header = (
    <header
      className={cx('sticky top-0 left-0 right-0 z-20 duration-600 ease-appear', {
        'shadow-header-scrolled': isHeaderScrolled && !isMobileMenuOpen,
        'bg-white': isHeaderScrolled || isMobileMenuOpen || forceOpaque,
      })}
      onMouseLeave={onMouseLeaveHeader}
    >
      <div className="px-container-mobile md:px-container-desktop">
        <div className="mx-auto max-w-grid">
          <div className="h-12 md:h-10 flex justify-between items-center">
            {logoColumn}
            {centerMenuColumn}
            {rightMenuColumn}
          </div>
        </div>
      </div>
      <HeaderDesktopMenu menu={desktopMenu} />
    </header>
  );

  return (
    <Fragment>
      {!isMobileMenuOpen && promoBanner}
      <HeaderMobileTakeover menu={mobileMenu} menuLinks={mobileMenuLinks} onClickMenuButton={onClickMenuButton} />
      {header}
    </Fragment>
  );
};

export default Header;
