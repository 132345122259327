import { useEffect, useState } from 'react';

const useWindowScroll = (): [number, number] => {
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const onScroll: EventListener = (event) => {
    var scrollTop = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
    var scrollLeft = window.scrollX || document.body.scrollLeft || document.documentElement.scrollLeft;

    setScrollY(scrollTop);
    setScrollX(scrollLeft);
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return [scrollY, scrollX];
};

export default useWindowScroll;
